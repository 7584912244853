





































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import BaseCard from "./BaseCard.vue";

@Component({
    components: {
        BaseCard,
    },
})
export default class BaseDialog extends Vue {
    @Prop() value!: boolean;
    @Prop() title!: string;
    @Prop({ default: 480 }) maxWidth!: number | string;

    get showDialog() {
        return this.value;
    }
    set showDialog(isActive: boolean) {
        this.$emit("input", isActive);
    }

    @Emit("dialog:close")
    closeDialog(isActive: boolean) {
        this.showDialog = false;
        return isActive;
    }
}
