

















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class BaseCard extends Vue {
    @Prop({ default: "white" }) color!: string;
    @Prop({ default: "mb-4" }) customClass!: string;
    @Prop({ default: false }) disabled!: boolean;
    @Prop({ default: false }) dark!: boolean;
}
