














import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BaseWrapper extends Vue {
    @Prop({ default: "medium" }) variant!: string;
    @Prop({ default: "base" }) color!: string;
    @Prop({ default: "100vh" }) minHeight!: string | number;
    @Prop({ default: "pa-0 pt-4 pa-md-6" }) customClass!: string;

    variantWidth(variant: string) {
        let maxWidth: string = "800px";

        switch (variant) {
            case "fullscreen":
                maxWidth = "100%";
                break;
            case "large":
                maxWidth = "1080px";
                break;
            case "small":
                maxWidth = "400px";
                break;
            default:
                break;
        }

        return `min-height: ${this.minHeight} !important; max-width: ${maxWidth}`;
    }
}
