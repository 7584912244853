









































































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import InputArray from "../Input/InputArray.vue";
import InputAutocomplete from "../Input/InputAutocomplete.vue";
import InputCombobox from "../Input/InputCombobox.vue";
import InputCurrency from "../Input/InputCurrency.vue";
import InputDate from "../Input/InputDate.vue";
import InputDefault from "../Input/InputDefault.vue";
import InputDescription from "../Input/InputDescription.vue";
import InputFile from "../Input/InputFile.vue";
import InputImage from "../Input/InputImage.vue";
import InputNumber from "../Input/InputNumber.vue";
import InputTextarea from "../Input/InputTextarea.vue";
import InputTitle from "../Input/InputTitle.vue";

@Component({
    components: {
        InputDefault,
        InputNumber,
        InputTitle,
        InputFile,
        InputAutocomplete,
        InputDescription,
        InputTextarea,
        InputCombobox,
        InputImage,
        InputDate,
        InputCurrency,
        InputArray,
    },
})
export default class BaseForm extends Vue {
    @Prop() forms!: any[];
    @Prop() data!: any;

    public forceUpdateCount: number = 0;

    get formValid() {
        let emptyFormCount: number = 0;
        const isEmpty = (value: any): boolean =>
            value === undefined || value === null || value === "";

        this.forms.map((form) => {
            if (form.required && isEmpty(this.data[form.value]))
                emptyFormCount++;
        });

        return emptyFormCount === 0;
    }

    @Emit("input:focus")
    setFocus(input: any) {
        return input;
    }

    @Watch("formValid")
    updateFormValid(valid: boolean) {
        this.$emit("form:valid", valid);
    }
}
