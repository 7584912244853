

























import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import * as rules from "@/helpers/InputRule";

@Component
export default class InputDefault extends Vue {
    @Prop() form!: any;
    @Prop() data!: any;

    get rules() {
        return rules;
    }

    get specialRules() {
        let specialRules: any[] = [
            (v: any) => !!v || "Tidak boleh kosong...",
            (v: any) => (v && v.startsWith("63")) || "Hanya KTP Banjarmasin",
        ];

        return specialRules;
    }

    get hasSpecialRules() {
        return this.form && this.form.specialRule;
    }

    get useRules() {
        let rules: any[] = [];

        if (this.form.rules) rules = [...rules, ...this.form.rules];
        if (this.hasSpecialRules) rules = [...this.specialRules, ...rules];
        if (this.form && this.form.required)
            rules = [...rules, (v: any) => !!v || "Tidak boleh kosong"];

        return rules;
    }

    @Emit("input:focus")
    setFocus(input: any) {
        return input;
    }
}
