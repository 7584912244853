































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import * as rules from "@/helpers/InputRule";

@Component
export default class InputDate extends Vue {
    @Prop() form!: any;
    @Prop() data!: any;

    get rules() {
        return rules;
    }

    get specialRules() {
        let specialRules: any[] = [
            (v: any) => !!v || "Tidak boleh kosong...",
            (v: any) => v.startsWith("6371") || "Hanya KTP Banjarmasin",
        ];

        return specialRules;
    }

    @Emit("input:focus")
    setFocus(input: any) {
        return input;
    }
}
