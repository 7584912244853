var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-wrapper',{attrs:{"variant":"fullscreen"}},[_c('base-card',{attrs:{"customClass":"pa-4 mb-4"}},[_c('v-layout',{staticClass:"align-center"},[_c('p',{staticClass:"mb-0 title font-weight-bold",domProps:{"textContent":_vm._s('Setup - Sponsor')}}),_c('v-spacer'),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","depressed":""},on:{"click":_vm.newSetup}},[_c('v-icon',{class:!_vm.$isMobile ? 'ml-n2' : '',domProps:{"textContent":_vm._s('mdi-plus-circle')}}),(!_vm.$isMobile)?_c('span',{staticClass:"ml-2 mr-n1 text-none",domProps:{"textContent":_vm._s('Tambah sponsor')}}):_vm._e()],1)],1)],1),_c('base-card',{attrs:{"customClass":"pa-4"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.sponsors,"sort-by":"created_at"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"text--secondary body-2",domProps:{"textContent":_vm._s(value)}})]}},{key:"item.image_path",fn:function(ref){
var value = ref.value;
return [_c('v-layout',[(value)?_c('v-img',{staticClass:"my-3",attrs:{"contain":"","width":"64","height":"64","src":_vm.loadFile(value)}}):_vm._e()],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('v-layout',[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.selectSponsor(item)}}},[_c('v-icon',{attrs:{"left":"","small":""},domProps:{"textContent":_vm._s('mdi-pencil')}}),_c('span',{staticClass:"text-none",domProps:{"textContent":_vm._s('Edit')}})],1),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.deleteSponsor(item)}}},[_c('v-icon',{attrs:{"left":"","small":""},domProps:{"textContent":_vm._s('mdi-delete')}}),_c('span',{staticClass:"text-none",domProps:{"textContent":_vm._s('Hapus')}})],1)],1)]}}])})],1)],1),_c('base-dialog',{attrs:{"title":"Tambah Sponsor"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('base-form',{staticClass:"mb-4",attrs:{"forms":_vm.forms,"data":_vm.formData},on:{"form:valid":function($event){_vm.isFormValid = $event}}}),_c('v-btn',{attrs:{"depressed":"","color":"primary","loading":_vm.isLoading,"disabled":!_vm.isFormValid},on:{"click":_vm.createOrUpdateSetup}},[_c('span',{staticClass:"text-none",domProps:{"textContent":_vm._s(_vm.isEditing ? 'Edit Sponsor' : 'Tambah Sponsor')}})])],1),_c('base-dialog',{attrs:{"title":"Hapus Sponsor"},model:{value:(_vm.showDialogDelete),callback:function ($$v) {_vm.showDialogDelete=$$v},expression:"showDialogDelete"}},[(_vm.formData && _vm.formData.name)?_c('v-sheet',{staticClass:"pa-3 mb-4 rounded-lg",attrs:{"color":"error lighten-5"}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error"},domProps:{"textContent":_vm._s('mdi-information')}}),_c('v-layout',{staticClass:"align-center",attrs:{"wrap":""}},[_c('p',{staticClass:"mb-0 mr-2 error--text",domProps:{"innerHTML":_vm._s(
                            ("Hapus <strong>" + (_vm.formData.name) + "</strong> sebagai sponsor ?")
                        )}})])],1)],1):_vm._e(),_c('v-btn',{attrs:{"text":"","color":"error","loading":_vm.isLoading},on:{"click":function($event){return _vm.confirmDeleteSponsor()}}},[_c('span',{staticClass:"text-none",domProps:{"textContent":_vm._s('Hapus Sponsor')}})]),_c('v-btn',{attrs:{"depressed":"","color":"button","disabled":_vm.isLoading},on:{"click":function($event){_vm.showDialogDelete = false}}},[_c('span',{staticClass:"text-none",domProps:{"textContent":_vm._s('Batal')}})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }