









import { Component, Vue } from "vue-property-decorator";
import AdminSetupElements from "@/components/Admin/Setup/Elements.vue";
import AdminSetupSponsors from "@/components/Admin/Setup/Sponsors.vue";

@Component({
    components: { AdminSetupSponsors, AdminSetupElements },
    metaInfo() {
        const title = "Setup";

        return {
            title: title,
        };
    },
})
export default class AppSetup extends Vue {
    // public isLoading: boolean = false
}
