

















import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import * as rules from "@/helpers/InputRule";

@Component
export default class InputDescription extends Vue {
    @Prop() form!: any;
    @Prop() data!: any;

    get rules() {
        return rules;
    }

    @Emit("input:focus")
    setFocus(input: any) {
        return input;
    }
}
