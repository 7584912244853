



























import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import * as rules from "@/helpers/InputRule";

@Component
export default class InputCurrency extends Vue {
    @Prop() form!: any;
    @Prop() data!: any;

    public errorMessage: string = "";
    public tempValue: string = "";

    get rules() {
        return rules;
    }

    toCurrency(value: any) {
        let numberToString: string = String(value)
            .replace(/[^,\d]/g, "")
            .toString();
        let splittedValue: string[] = numberToString.split(",");
        let remaining: number = splittedValue[0].length % 3;
        let currency: string = splittedValue[0].substr(0, remaining);
        let thousands: any = splittedValue[0]
            .substr(remaining)
            .match(/\d{3}/gi);
        let separator: string = remaining ? "." : "";

        if (thousands) currency += separator + thousands.join(".");

        const isOverThousand = !!splittedValue[1];

        if (isOverThousand) currency + "," + splittedValue[1];

        this.tempValue = currency;
        this.currencyToNumber(currency);

        return currency;
    }

    currencyToNumber(value: string) {
        this.data[this.form.value] = Number(value.replace(/\./g, ""));
    }

    checkMaxFund() {
        this.errorMessage = "";

        if (this.data[this.form.value] > this.form.max) {
            this.data[this.form.value] = this.form.max;
            this.tempValue = this.toCurrency(this.form.max);
            // this.lastValue = this.form.max;
            this.errorMessage =
                "Maksimal dana yang diberikan " +
                this.toCurrency(this.form.max);
        }
    }

    @Emit("input:focus")
    setFocus(input: any) {
        return input;
    }

    mounted() {
        setTimeout(() => {
            const numberToString = String(this.data[this.form.value]);

            this.tempValue = this.toCurrency(numberToString) || "";
        }, 100);
    }
}
