


















import { Component, Prop, Vue } from "vue-property-decorator";
import * as rules from "@/helpers/InputRule";

@Component
export default class InputFile extends Vue {
    @Prop() form!: any;
    @Prop() data!: any;

    get rules() {
        return rules;
    }

    fileAccept(fileType: any) {
        let accept = "";

        switch (fileType) {
            case "all":
                accept = "*";
                break;
            case "pdf":
                accept = "application/pdf";
                break;
            case "image":
                accept = "image/png, image/jpeg, image/jpg";
                break;
            default:
                break;
        }

        return accept;
    }

    fileIcon(fileType: any) {
        let icon = "mdi-paperclip";

        switch (fileType) {
            case "all":
                icon = "mdi-paperclip";
                break;
            case "pdf":
                icon = "mdi-file-pdf";
                break;
            case "image":
                icon = "mdi-file-image";
                break;
            default:
                break;
        }

        return icon;
    }

    get useRules() {
        let rules: any[] = [];

        if (this.form.rules) rules = [...this.form.rules, ...rules];
        if (this.form && this.form.required)
            rules = [...this.rules.required, ...rules];

        return rules;
    }
}
