























































import BaseDialog from "../Base/BaseDialog.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import * as rules from "@/helpers/InputRule";
import { loadFile } from "@/helpers/useLoader";

@Component({
    components: { BaseDialog },
})
export default class InputImage extends Vue {
    @Prop() form!: any;
    @Prop() data!: any;

    public showDialog: boolean = false;

    loadFile(path: string) {
        return loadFile(path);
    }

    get isImageSelected(): boolean {
        return this.data && this.data[this.form.value];
    }

    get imgSrc() {
        let src = "#";

        if (this.isImageSelected)
            src = URL.createObjectURL(this.data[this.form.value]);

        return src;
    }

    get hasImage(): boolean {
        let hasImage: boolean = this.data && this.data[this.form.imageValue];

        return hasImage;
    }

    get rules() {
        return rules;
    }

    fileAccept(fileType: any) {
        let accept = "";

        switch (fileType) {
            case "all":
                accept = "*";
                break;
            case "pdf":
                accept = "application/pdf";
                break;
            case "image":
                accept = "image/png, image/jpeg, image/jpg";
                break;
            default:
                break;
        }

        return accept;
    }

    fileIcon(fileType: any) {
        let icon = "mdi-paperclip";

        switch (fileType) {
            case "all":
                icon = "mdi-paperclip";
                break;
            case "pdf":
                icon = "mdi-file-pdf";
                break;
            case "image":
                icon = "mdi-file-image";
                break;
            default:
                break;
        }

        return icon;
    }

    updateShowDialog() {
        this.showDialog = !this.showDialog;
    }

    get useRules() {
        let rules: any[] = [];

        if (this.form.rules) rules = [...this.form.rules, ...rules];
        if (this.form && this.form.required)
            rules = [...this.rules.required, ...rules];

        return rules;
    }
}
