






















































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import * as rules from "@/helpers/InputRule";

@Component
export default class InputArray extends Vue {
    @Prop() form!: any;
    @Prop() data!: any;

    public forceUpdateCount: number = 0;

    get rules() {
        return rules;
    }

    get specialRules() {
        let specialRules: any[] = [
            (v: any) => !!v || "Tidak boleh kosong...",
            (v: any) => v.startsWith("63") || "Hanya KTP Banjarmasin",
        ];

        return specialRules;
    }

    @Emit("input:focus")
    setFocus(input: any) {
        return input;
    }

    addArray() {
        this.data[this.form.value].push("");
        this.forceUpdate();
    }

    deleteArray(index: number) {
        this.data[this.form.value].splice(index, 1);
        this.forceUpdate();
    }

    onBlur() {
        this.forceUpdate();
    }

    forceUpdate() {
        this.forceUpdateCount++;
        this.$emit("forceUpdate");
    }

    mounted() {
        const isArray = Array.isArray(this.data[this.form.value]);

        if (!isArray) this.data[this.form.value] = [""];
        setTimeout(() => {
            this.forceUpdateCount++;
        }, 100);
    }
}
