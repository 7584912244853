



















































































import BaseDialog from "../../Base/BaseDialog.vue";
import BaseForm from "../../Base/BaseForm.vue";
import BaseWrapper from "../../Base/BaseWrapper.vue";
import BaseCard from "../../Base/BaseCard.vue";
import { Vue, Component } from "vue-property-decorator";
import { noSpace } from "@/helpers/InputRule";

@Component({
    components: { BaseCard, BaseWrapper, BaseForm, BaseDialog },
})
export default class AdminSetupElements extends Vue {
    public isLoading: boolean = false;
    public showDialog: boolean = false;
    public isFormValid: boolean = false;
    public formData: any = {};
    public forms: any[] = [
        {
            text: "Element",
            placeholder: "Isi id element",
            value: "name",
            withLabel: true,
            rules: [...noSpace],
            disabled: true,
        },
        {
            text: "Value",
            placeholder: "Isi value dari element",
            value: "value",
            withLabel: true,
            required: true,
        },
    ];

    public headers: any[] = [
        {
            text: "Nama Element",
            value: "name",
            sortable: false,
        },
        {
            text: "Value",
            value: "value",
            sortable: false,
        },
        {
            text: "",
            value: "created_at",
            width: "1%",
            sortable: false,
        },
    ];

    get elements() {
        return this.$store.state.elements.elements;
    }

    get isEditing() {
        return this.formData && this.formData.id;
    }

    newSetup() {
        this.formData = {};
        this.showDialog = true;
    }

    async createSetup() {
        this.isLoading = true;

        const response = await this.$store.dispatch(
            "elements/addElement",
            this.formData
        );

        setTimeout(() => {
            this.showDialog = false;
            this.isLoading = false;
            this.formData = {};
        }, 500);
    }

    async updateSetup() {
        this.isLoading = true;

        const response = await this.$store.dispatch(
            "elements/updateElement",
            this.formData
        );

        setTimeout(() => {
            this.showDialog = false;
            this.isLoading = false;
            this.formData = {};
        }, 500);
    }

    async getElements() {
        await this.$store.dispatch("elements/getElements");
    }

    selectElement(item: any) {
        this.formData = { ...item };
        this.showDialog = true;
        this.isFormValid = true;
    }

    createOrUpdateSetup() {
        if (this.isEditing) return this.updateSetup();
        return this.createSetup();
    }

    mounted() {
        this.getElements();
    }
}
