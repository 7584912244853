

































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import * as rules from "@/helpers/InputRule";

@Component
export default class InputAutocomplete extends Vue {
    @Prop() form!: any;
    @Prop() data!: any;

    public search: string = "";
    public firstUpdate: boolean = false;

    async createCategory() {
        console.log("Create category:", this.search);

        const addCategory = await this.$store.dispatch(
            "categories/addCategory",
            this.search
        );

        if (addCategory && addCategory.category)
            this.data.category = addCategory.category;
    }

    get rules() {
        return rules;
    }

    @Emit("input:focus")
    setFocus(input: any) {
        return input;
    }

    @Watch("data", { deep: true, immediate: false })
    updateSearch(newVal: any) {
        if (!this.firstUpdate) {
            this.search = newVal[this.form.value];
            this.firstUpdate = true;
        }
    }
}
