

















































































































































import BaseDialog from "../../Base/BaseDialog.vue";
import BaseForm from "../../Base/BaseForm.vue";
import BaseWrapper from "../../Base/BaseWrapper.vue";
import BaseCard from "../../Base/BaseCard.vue";
import { Vue, Component } from "vue-property-decorator";
import { PRODUCTION_MODE } from "@/constants";
import { loadFile } from "@/helpers/useLoader";

@Component({
    components: { BaseCard, BaseWrapper, BaseForm, BaseDialog },
})
export default class AdminSetupSponsors extends Vue {
    public isLoading: boolean = false;
    public showDialog: boolean = false;
    public showDialogDelete: boolean = false;
    public isFormValid: boolean = false;
    public formData: any = {};
    public forms: any[] = [
        {
            text: "Perusahaan",
            placeholder: "Isi nama perusahaan",
            value: "name",
            withLabel: true,
            required: true,
        },
        {
            text: "Logo",
            type: "image",
            placeholder: "Upload logo perusahaan",
            value: "sponsor",
            withLabel: true,
            required: true,
            imageValue: "image_path",
        },
    ];

    public headers: any[] = [
        {
            text: "Perusahaan",
            value: "name",
        },
        {
            text: "Logo",
            value: "image_path",
            sortable: false,
            width: "1%",
        },
        {
            text: "",
            value: "created_at",
            width: "1%",
            align: "right",
            sortable: false,
        },
    ];

    get PRODUCTION_MODE() {
        return PRODUCTION_MODE;
    }

    get sponsors() {
        return this.$store.state.sponsors.sponsors;
    }

    get isEditing() {
        return this.formData && this.formData.id;
    }

    newSetup() {
        this.formData = {};
        this.showDialog = true;
    }

    async createSetup() {
        this.isLoading = true;

        const response = await this.$store.dispatch(
            "sponsors/addSponsor",
            this.formData
        );

        console.log("formData:", this.formData);

        setTimeout(() => {
            this.showDialog = false;
            this.isLoading = false;
            this.formData = {};
        }, 500);
    }

    async updateSetup() {
        this.isLoading = true;

        const response = await this.$store.dispatch(
            "sponsors/updateSponsor",
            this.formData
        );

        console.log("formData:", this.formData);

        setTimeout(() => {
            this.showDialog = false;
            this.isLoading = false;
            this.formData = {};
        }, 500);
    }

    async getSponsors() {
        await this.$store.dispatch("sponsors/getSponsors");
    }

    selectSponsor(item: any) {
        this.formData = { ...item };
        this.showDialog = true;
        this.isFormValid = true;
    }

    deleteSponsor(item: any) {
        this.formData = { ...item };
        this.showDialogDelete = true;
    }

    createOrUpdateSetup() {
        if (this.isEditing) return this.updateSetup();
        return this.createSetup();
    }

    async confirmDeleteSponsor() {
        this.isLoading = true;

        const response = await this.$store.dispatch(
            "sponsors/deleteSponsor",
            this.formData
        );

        setTimeout(() => {
            this.showDialogDelete = false;
            this.isLoading = false;
            this.formData = {};
        }, 500);
    }

    loadFile(path: string) {
        return loadFile(path);
    }

    mounted() {
        this.getSponsors();
    }
}
